export const imagesDrones = [
  {
    original: "photos/drones/01.jpg",
    thumbnail: "photos/drones/01.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/drones/02.jpg",
    thumbnail: "photos/drones/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/drones/03.jpg",
    thumbnail: "photos/drones/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/drones/04.jpg",
    thumbnail: "photos/drones/04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
];