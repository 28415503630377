import './copy-text.component.scss'
import copy from 'copy-to-clipboard'

import { useState } from 'react'

const CopyTextComponent = ({ children }) => {
  const [isCopied, setIsCopied] = useState(false)

  const onClick = () => {
    console.log(children)
    copy(children)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 300)
  }
  return (
    <span className='copy-text-component' onClick={onClick}>
      {children}
      <span className='copy-text-copy'>
        <img src='images/icon-copy.svg' className='copy-icon' alt='' />
        <img
          src='images/icon-check.svg'
          className={`copy-icon-check ${isCopied ? 'active' : ''}`}
          alt=''
        />
        <span className={`copy-text-copied ${isCopied ? 'active' : ''}`}>
          copied
        </span>
      </span>
    </span>
  )
}

export default CopyTextComponent
