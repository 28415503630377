import './tabs.component.scss'
import { useState } from 'react'

const Tabs = ({ children, className = '', ...props }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <section className={`tabs-component ${className}`} {...props}>
      <section className='tabs-button-list'>
        {children.map(([title, content], i) => (
          <button
            className={`tabs-button ${activeTab === i ? 'active' : ''}`}
            onClick={() => setActiveTab(i)}
            key={i}
          >
            {title}
          </button>
        ))}
      </section>
      <section className='tabs-content-list'>
        {children.map(([title, content], i) => (
          <section
            className={`tabs-content ${activeTab === i ? 'active' : ''}`}
            key={i}
          >
            {content}
          </section>
        ))}
      </section>
    </section>
  )
}

export default Tabs
