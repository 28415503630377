import "./home-page.component.scss";
import LinkScroll from "../common/link-scroll/link-scroll.component";
import React, { useState } from "react";
import DronesSection from "./sections/drones-section";
import tt, { changeLang, getInitialLang } from "../../helpers/translate.helper";
import IconsList from "../contacts/iconsList.component.js";

const HomePageComponent = () => {
  const [lang, setLang] = useState(getInitialLang());
  const [openMenu, setOpenMenu] = useState(false);
  const onLangClick = (value) => {
    changeLang(value);
    setLang(value);
  };

  return (
    <div className="home-page-component">
      <div className="container">
        <nav className="site-nav">
          <button
            className={
              openMenu ? "mobile-toggle-btn menu-active" : "mobile-toggle-btn"
            }
            onClick={() => setOpenMenu(!openMenu)}
          ></button>
          <div
            className={
              openMenu ? "container-nav  menu-active" : "container-nav"
            }
          >
            <LinkScroll to="#about-project" data-tr>
              {tt("Про проект")}
            </LinkScroll>
            <LinkScroll to="#donate" data-tr>
              {tt("Підтримати")}
            </LinkScroll>
            <LinkScroll to="#contacts" data-tr>
              {tt("Контакти")}
            </LinkScroll>
          </div>
          {openMenu && (
            <div
              className="overlayForCloseMenu"
              onClick={() => setOpenMenu(false)}
            ></div>
          )}
          <section className="lang-nav">
            <button
              onClick={() => onLangClick("UA")}
              className={`${lang === "UA" ? "active" : ""}`}
            >
              UA
            </button>
            <button
              onClick={() => onLangClick("EN")}
              className={`${lang === "EN" ? "active" : ""}`}
            >
              EN
            </button>
          </section>
        </nav>
      </div>
      <section id="about-project" className="top-level-section">
        <div className="section-title-wrapper">
          <h2 className="section-title" data-tr>
            {tt("Про проект")}
          </h2>
        </div>
        <DronesSection />
      </section>
      <section id="contacts" className="top-level-section">
        <div className="section-title-wrapper">
          <h2 className="section-title" data-tr>
            {tt("Контакти")}
          </h2>
        </div>

        <div className="container">
          <div className="contacts-wrapper">
            <p className="contacts-row">{tt("Харків, Україна")}</p>
            <a className="contacts-row" href="mailto:bslhelporg@gmail.com">
              bslhelporg@gmail.com
            </a>
            <IconsList />
            <a className="contacts-row go-home" href="https://bslhelp.org.ua">
              {tt("Повернутися додому")}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePageComponent;
