import './copy-text.component.scss'
import copy from 'copy-to-clipboard'

import { useState } from 'react'

const CopyTextWithoutIconComponent = ({ children }) => {
  const [isCopied, setIsCopied] = useState(false)

  const onClick = () => {
    copy(children)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 300)
  }
  return (
    <span className='copy-text-component' onClick={onClick}>
      {children}
      <span className='copy-text-copy'>
        <span className={`copy-text-copied ${isCopied ? 'active' : ''}`}>
          copied
        </span>
      </span>
    </span>
  )
}

export default CopyTextWithoutIconComponent