import "./grid-col.component.scss";

const GridCol = ({ children, cols = 12, className = "", ...props }) => {
  return (
    <section className={`grid-col-component cols-${cols} ${className}`} {...props}>
      {children}
    </section>
  );
};

export default GridCol;
